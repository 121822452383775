import React, { useState, useEffect, useRef, useCallback } from 'react';
import AudioControls from './AudioControl';
import spotify from '../../assets/spotify.png';
import './AudioPlayer.scss';

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
const AudioPlayer = ({ track }) => {
  // State
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Destructure for conciseness
  const { title, artist, image, audioSrc, spotifyLink } = track;

  // Refs
  const audioRef = useRef(typeof Audio !== 'undefined' && new Audio(audioSrc));
  const intervalRef = useRef();

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #2d5dad), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = useCallback(() => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        console.log('finished');
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  }, []);

  const onScrub = value => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  useEffect(
    () =>
      // Pause and clean up on unmount
      () => {
        audioRef.current.pause();
        clearInterval(intervalRef.current);
      },
    []
  );

  const playAudio = startPause => {
    if (startPause) {
      setIsPlaying(true);
      audioRef.current.play();
      startTimer();
    } else {
      setIsPlaying(false);
      audioRef.current.pause();
    }
  };

  return (
    <>
      <div className="track-info">
        <h2 className="title">{title}</h2>
        <div className="art-container">
          {image && <img className="artwork" src={image} alt={`track artwork for ${title} by ${artist}`} />}
          {spotifyLink && (
            <a href={spotifyLink} target="_blank" rel="noreferrer" className="spotify-container">
              <img className="spotify" src={spotify} alt="Spotify" />
            </a>
          )}
        </div>
        <div className="controls">
          <AudioControls isPlaying={isPlaying} onPlayPauseClick={playAudio} />
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration || `${duration}`}
            className="progress"
            onChange={e => onScrub(e.target.value)}
            style={{ background: trackStyling }}
          />
        </div>
      </div>
    </>
  );
};

export default AudioPlayer;
