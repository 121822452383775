import React, { useState } from 'react';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import AudioPlayer from '../../Audio/AudioPlayer';
import ellaEs from '../../../assets/canciones/ELLA ES-V2-MSR-(320).mp3';
import prometo from '../../../assets/canciones/PROMETO.mp3';
import tu from '../../../assets/canciones/TU.mp3';
import yoTeQuiero from '../../../assets/canciones/Yo-te-quiero.mp3';
import imgSrc from '../../../assets/ella-es.jpeg';
import prometoCover from '../../../assets/prometo-cover.png';
import tuCover from '../../../assets/tu-cover.png';
import quieroCover from '../../../assets/quiero-cover.png';

export const OwnAudios = () => {
  const [playing, setPlaying] = useState(false);
  return (
    <div className="work-audios">
      <ScrollRevealAnimation animation="fadeIn" delay={0.95} offset="20px">
        <div className="title-section">Audios de mi música</div>
      </ScrollRevealAnimation>
      <ScrollRevealAnimation animation="fadeIn" delay={0.95} offset="20px">
        <div className="description">
          Acá encontrarás una muestra de mis obras musicales desde mis inicios hasta hoy. <br />
          Estos sencillos hacen parte de mi trabajo personal en curso.
        </div>
      </ScrollRevealAnimation>
      <div className="container">
        <ScrollRevealAnimation animation="fadeIn" delay={0.25} offset="20px" className="audio-player">
          <AudioPlayer
            track={{
              title: 'Ella es',
              audioSrc: ellaEs,
              image: imgSrc,
              spotifyLink: 'https://open.spotify.com/track/58M7rAoqeLnOq8kDSXSBFg?si=2f534768ece74b1b',
            }}
          />
        </ScrollRevealAnimation>
        <ScrollRevealAnimation animation="fadeIn" delay={0.5} offset="20px" className="audio-player">
          <AudioPlayer
            track={{
              title: 'Prometo',
              audioSrc: prometo,
              image: prometoCover,
              spotifyLink: 'https://open.spotify.com/track/0kOMYyIE6TScLbq5pAQOFz?si=dc2c0dcf4a284cd1',
            }}
          />
        </ScrollRevealAnimation>
        <ScrollRevealAnimation animation="fadeIn" delay={0.75} offset="20px" className="audio-player">
          <AudioPlayer
            track={{
              title: 'Tu',
              audioSrc: tu,
              image: tuCover,
              spotifyLink: 'https://open.spotify.com/track/7pJHhaLKJm5hYrs5xOlNUU?si=1f6dfa7297ed43bc',
            }}
          />
        </ScrollRevealAnimation>
        <ScrollRevealAnimation animation="fadeIn" delay={1} offset="20px" className="audio-player">
          <AudioPlayer
            track={{
              title: 'Yo te quiero',
              audioSrc: yoTeQuiero,
              image: quieroCover,
              spotifyLink: 'https://open.spotify.com/track/6nNJvVToBZI7lbZSHUj55r?si=720fae8b796f429f',
            }}
          />
        </ScrollRevealAnimation>
      </div>
    </div>
  );
};
