import React from 'react';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import AudioPlayer from '../../Audio/AudioPlayer';
import inpire from '../../../assets/trabajo/INPIRE-GOLD.mp3';
import miReel from '../../../assets/trabajo/MI-REEL.mp3';

export const WorkAudios = () => (
  <div className="work-audios">
    <ScrollRevealAnimation animation="fadeIn" delay={0.95} offset="20px">
      <div className="title-section">Audios de mi trabajo</div>
    </ScrollRevealAnimation>
    <ScrollRevealAnimation animation="fadeIn" delay={0.95} offset="20px">
      <div className="description">En "Mi Reel" encontrarás pequeños apartes de algunas de las cosas que he hecho.</div>
    </ScrollRevealAnimation>
    <div className="container">
      <ScrollRevealAnimation animation="fadeIn" delay={0.25} offset="20px" className="audio-player">
        <AudioPlayer
          track={{
            title: 'INSPIRE GOLD',
            audioSrc: inpire,
            image: null,
          }}
        />
      </ScrollRevealAnimation>
      <ScrollRevealAnimation animation="fadeIn" delay={0.5} offset="20px" className="audio-player">
        <AudioPlayer
          track={{
            title: 'MI REEL',
            audioSrc: miReel,
            image: null,
          }}
        />
      </ScrollRevealAnimation>
    </div>
  </div>
);
