import React from 'react';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import { OwnAudios } from './OwnAudios';
import { WorkAudios } from './WorkAudios';
import './Projects.scss';

export const Projects = () => (
  <div className="wrapper" id="projects">
    <div className="portfolio">
      <ScrollRevealAnimation animation="subtleFadeInDown" delay={0.5} duration={1} offset="20px">
        <h2 className="portfolio-title">Portafolio</h2>
      </ScrollRevealAnimation>
      <ScrollRevealAnimation animation="subtleFadeInDown" delay={0.65} duration={1} offset="20px">
        <p>
          Cuento con más de 10 años de experiencia en la producción, edición y mezcla de audios para comerciales, cuñas,
          IVRs o contestadores telefónicos, audios internos, videos institucionales y todo tipo de audios para
          publicidad.
        </p>

        <p>
          Tengo un amplio manejo de diferentes DAW, aunque debo confesar que mi fuerte es Pro-tools. <br />
        </p>
        <p>
          Adicionalmente compongo y produzco temas musicales, mayormente propios dado que trabajo actualmente en mi
          proyecto personal.
        </p>
      </ScrollRevealAnimation>
      <WorkAudios />
      <OwnAudios />
    </div>
  </div>
);
