import React from 'react';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import { Header } from 'components/theme';
import './Intro.scss';

export const Intro = () => (
  <div className="intro">
    <Header />
    <div className="wrapper">
      <div className="intro-wrapper">
        <div className="overlay" />
        <div className="details">
          <ScrollRevealAnimation animation="zoomIn" delay={0.5} duration={1} offset="20px">
            <h1>
              Hola,
              <br /> Soy <span>Nana Salazar.</span>
              <br />
              Ingeniera de Sonido.
            </h1>
          </ScrollRevealAnimation>
          <ScrollRevealAnimation animation="subtleFadeInLeft" delay={0.95} offset="20px">
            <h4> - También compongo y produzco temas musicales. - </h4>
          </ScrollRevealAnimation>
        </div>
      </div>
    </div>
  </div>
);
