import React from 'react';

const AudioControls = ({ isPlaying, onPlayPauseClick }) => (
  <div className="audio-controls">
    {isPlaying ? (
      <button type="button" className="pause" onClick={() => onPlayPauseClick(false)} aria-label="Pause">
        <img src={require('../../assets/pause.png')} alt="Pause" style={{ width: '45px' }} />
      </button>
    ) : (
      <button type="button" className="play" onClick={() => onPlayPauseClick(true)} aria-label="Play">
        <img src={require('../../assets/play.png')} alt="Play" style={{ width: '45px' }} />
      </button>
    )}
  </div>
);

export default AudioControls;
