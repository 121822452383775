import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const WrapperInside = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
  // transform: skewY(1deg);
  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (min-width: 960px) {
    width: 80%;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding-right: 2rem;

  @media (max-width: 960px) {
    padding-right: unset;
    width: 100%;
    order: 1;
  }

  h2 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: #343434;
    //color: ${({ theme }) => (theme !== 'light' ? '#ffffff' : '#212121')};
    text-transform: uppercase;
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: #707070;
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;
