import React from 'react';
import { Container } from 'components/common';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import dev from '../../../assets/illustrations/nana2.png';
import './Skills.scss';

export const Skills = () => (
  <div className="s-wrapper" id="about">
    <div className="skills-wrapper" as={Container}>
      <div className="thumbnail">
        <ScrollRevealAnimation animation="subtleFadeInLeft" delay={0} offset="0px">
          <img src={dev} alt="Ilustración Marión Carámbula" />
        </ScrollRevealAnimation>
      </div>
      <div className="details">
        <ScrollRevealAnimation animation="subtleFadeInRight" delay={0.25} offset="20px">
          <h1>Acerca de mí</h1>
        </ScrollRevealAnimation>
        <ScrollRevealAnimation animation="subtleFadeInRight" delay={0.5} offset="20px">
          <p>
            Mi nombre es <span>Johanna Salazar Díaz</span>. Soy ingeniera de sonido egresada de la universidad{' '}
            <span>San Buenaventura Bogotá</span>. Desde muy pequeña he sentido un amor inmenso por la música, lo que me
            ha llevado a aprender de manera empírica algunos{' '}
            <span>instrumentos de percusión menor, guitarra, cuatro, bajo,</span> entre otros. Hace unos años estudié
            guitarra (mi instrumento principal) y trompeta en la academia de música
            <span> AMA</span>.
          </p>
          <p>
            Actualmente me encuentro haciendo un curso de <span>producción online</span> en la Pontificia Universidad
            Javeriana y tomando clases particulares online con el <span>músico y productor Marcos Sánchez</span>.
          </p>
        </ScrollRevealAnimation>
      </div>
    </div>
  </div>
);
